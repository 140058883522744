

























































import Vue from 'vue';
import { Practice } from '@/models/practice.model';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { db } from '@/main';
import algoliasearch from 'algoliasearch';
import constants from '../constants/constants';

export default Vue.extend({
  name: 'SwitchPractice',
  data() {
    return {
      selectedPractice: '',
      search: null,
      practices: [] as Practice[],
      footerProps: { 'items-per-page-options': [20, 50, 100, 200, 500, 1000] },
      headers: [
        {
          value: 'name',
          text: 'Name',
          sortable: true
        }
      ],
      options: {} as any,
      page: 1,
      totalRows: 1,
      perPage: { value: 50, text: '50 per page' },
      dataListener: (null as null | {}) as () => void,
      isBusy: false,
      isSearchLoading: false,
      previousPageNumber: 1,
      topVisible: {} as firebase.firestore.QueryDocumentSnapshot<
        firebase.firestore.DocumentData
      >,
      bottomVisible: {} as firebase.firestore.QueryDocumentSnapshot<
        firebase.firestore.DocumentData
      >,
      client: algoliasearch(
        constants.ALGOLIA.APP_ID,
        constants.ALGOLIA.SEARCH_KEY
      ),
      searchResults: []
    };
  },
  methods: {
    initData() {
      this.getTotalPracticeCount();
      this.getData();
    },
    getData() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      this.isBusy = true;
      let query = this.mainQuery;
      let order = 'asc' as 'asc' | 'desc';
      let sort = 'name';
      if (sortBy && sortBy.length > 0) {
        sort = sortBy[0];
      }
      if (sortDesc !== undefined && sortDesc.length > 0) {
        order = sortDesc[0] ? 'desc' : 'asc';
      }
      query = query.orderBy(sort, order);
      const pageDiff = (page ?? 1) - this.previousPageNumber;
      this.previousPageNumber = page ?? 1;
      switch (pageDiff) {
        case 0:
          query = query.limit(itemsPerPage ?? this.perPage.value);
          break;
        case 1:
          query = query
            .startAfter(this.bottomVisible)
            .limit(itemsPerPage ?? this.perPage.value);
          break;
        case -1:
          query = query
            .endBefore(this.topVisible)
            .limitToLast(itemsPerPage ?? this.perPage.value);
          break;
        default:
          query = query.limit(itemsPerPage ?? this.perPage.value);
      }
      if (this.dataListener) {
        this.dataListener();
      }
      this.dataListener = query.onSnapshot(
        (snapshot: firebase.firestore.QuerySnapshot) => {
          this.isBusy = false;
          if (snapshot.docs.length > 0) {
            this.practices = snapshot.docs.map(
              value => value.data() as Practice
            );
            this.topVisible = snapshot.docs[0];
            this.bottomVisible = snapshot.docs[snapshot.docs.length - 1];
          } else {
            this.practices = [];
          }
        },
        (error: Error) => {
          console.error(error);
        }
      );
    },
    getTotalPracticeCount() {
      this.algoliaClient
        .search('', {
          filters: this.$store.state.currentUser.practiceUids
            .map(uid => `uid:${uid}`)
            .join(' OR ')
        })
        .then(({ nbHits }: any) => {
          this.totalRows = nbHits > 0 ? nbHits : 1;
        })
        .catch((err: any) => {
          console.log(err);
          return null;
        });
    },
    selectedRow(practice: Practice) {
      db.collection('users')
        .doc(this.$store.state.currentUser.uid)
        .set(
          {
            activePractice: {
              name: practice.name,
              uid: practice.uid
            }
          },
          { merge: true }
        )
        .then(() => {
          this.$router.push({ name: 'PracticeProfile' });
        })
        .catch(error => {
          console.error(error);
        });
    },
    getSearchResults(value: string) {
      this.isSearchLoading = true;
      this.algoliaClient
        .search(value, {
          filters: this.$store.state.currentUser.practiceUids
            .map(uid => `uid:${uid}`)
            .join(' OR '),
          hitsPerPage: 5
        })
        .then(({ hits }: any) => {
          this.isSearchLoading = false;
          this.searchResults = hits.map((hit: any) => {
            const item = { ...hit };
            delete item.objectID;
            const practice = item as Practice;
            return {
              value: practice.uid,
              text: practice.name
            };
          });
        })
        .catch((err: any) => {
          this.isSearchLoading = false;
          console.log(err);
          return null;
        });
    },
    addPractice() {
      this.$router.push({ name: 'NewPractice' });
    }
  },
  computed: {
    algoliaClient(): any {
      return this.client.initIndex(constants.ALGOLIA.INDEXES.PRACTICES);
    },
    mainQuery() {
      return db
        .collection('practices')
        .where('uid', 'in', this.$store.state.currentUser.practiceUids);
    }
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true
    },
    selectedPractice() {
      if (this.selectedPractice) {
        const practice = this.practices.find(
          value => value.uid === this.selectedPractice
        ) as Practice;
        this.selectedPractice = '';
        db.collection('users')
          .doc(this.$store.state.currentUser.uid)
          .set(
            {
              activePractice: {
                name: practice.name,
                uid: practice.uid
              }
            },
            { merge: true }
          )
          .then(() => {
            this.$router.push({ name: 'PracticeProfile' });
          })
          .catch(error => {
            console.error(error);
          });
      }
    },
    search(val) {
      if (val) {
        this.getSearchResults(val);
      } else {
        this.searchResults = [];
      }
    }
  },
  created() {
    this.initData();
  }
});
